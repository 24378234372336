import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button,Toolbar} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import language from '../layout/language';

import classNames from 'classnames';
import Utils from '../api/api';
import {styles} from '../layout/theme';

class SettingDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      _id:'',
      setting: {
        setting: '',
        settingVN: '',
        settingEN: '',
        //settingKR: '',
      },
      error: {
        setting_error: false,
        settingVN_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
    }
  }

  componentDidMount(){
    var $this = this;
    var params = this.context.router.route.match.params;

    if(params.id!==undefined){
      this.setState({isUpdate:true},() => {
        Utils.getSingleData('settings',params.id,(res) => {
          if(res.status === 'success' && res.results !== undefined) {
            $this.setState({setting:res.results});
          }
        });
      });
    }
  }

  handleChange(e){
    const $this = this,
        setting = $this.state.setting;

    setting[e.target.id] = e.target.value;
    $this.setState({setting: setting});
  }

  handleValidate(e){
    const $this = this,
        setting = $this.state.setting,
        obj = this.state.error;

    setting[e.target.id] = e.target.value;
    if (e.target.id === 'setting') setting[e.target.id] = e.target.value.trim();
    const p = e.target.id + '_error';
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    this.setState({setting: setting, error:obj});
  }

  _save() {
    var $this = this;
    var data = this.state.setting;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('setting',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/setting/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('setting',data, function(res) {

        if (res.status == 'success') {
          $this.context.router.history.push('/setting/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }

  handleSave = () => {
    const params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    const $this = this;

    const obj = this.state.error;
    obj.setting_error = this.state.setting.setting===''||this.state.setting.setting===null;
    obj.settingVN_error = this.state.setting.settingVN===''||this.state.setting.settingVN===null;

    this.setState({error:obj},() => {
      if (obj.setting_error||obj.settingVN_error) {
        return false;
      } else {
        $this._save();
      }
    });
  }

  handleCancel() {
    this.context.router.history.push('/setting/');
  }

  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    return(
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa Cấu hình</Typography>
        </Toolbar>
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error.error && classes.hide)}>{language.VN.error.require}</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
          <TextField id="setting" label="Key" className={classes.textField} value={this.state.setting.setting} onChange={(event) => this.handleValidate(event)} onBlur={(event) => this.handleValidate(event)} required={true} error={this.state.error.setting_error} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField multiline={true} rows={3} id="settingVN" label="Giá trị" className={classes.textField} value={this.state.setting.settingVN} onChange={(event) => this.handleValidate(event)} onBlur={(event) => this.handleValidate(event)} required={true} error={this.state.error.settingVN_error} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField multiline={true} rows={3} id="settingEN" label="Value" className={classes.textField} value={this.state.setting.settingEN} onChange={(event) => this.handleChange(event)} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          {/* <TextField multiline={true} rows={3} id="settingKR" label="값" className={classes.textField} value={this.state.setting.settingKR} onChange={(event) => this.handleChange(event)} margin="normal" InputLabelProps={{
            shrink: true
          }}/> */}
          <FormGroup row className={classes.formRow}>
            <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
            <Button variant="contained" color="primary" onClick={ this.handleSave}>{language.VN.btnSave}</Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

SettingDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
SettingDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(SettingDetail);
