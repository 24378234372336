import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Button,Toolbar,TextField} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import language from '../layout/language';

import {styles} from '../layout/theme';
import Utils from '../api/api';
import ReactTable from './shares/react_table';

export class SettingList extends Component {
  constructor(props){
    super(props);
    this.state = {
      requiredSetting: {
        emailServer: '',
        emailPort: '',
        emailSecure: '',
        emailService: '',
        emailUser: '',
        emailPass: ''
      },
      keyword: '',
      searchClick: false,
      sorts: JSON.stringify({setting:1})
    }
  }
  componentDidMount() {
    const $this = this;
    Utils.getData('setting/key','requiredSetting',function(data){
      if(data.status=='success'&&data.results.length){
        var obj = data.results[0].settingVN;
        obj.emailPass= Utils.atou(obj.emailPass);
        $this.setState({requiredSetting: obj},function(){
          //console.log($this.state.requiredSetting);
        });
      }
    });
  }

  handleSearch = (e) =>{
    const el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  
  handleAdd = () => {
    this.context.router.history.push('/setting/add');
  }
  
  requiredSettingChange = (e) => {
    const r = this.state.requiredSetting;
    //console.log(e.target.id);
    r[e.target.id] = e.target.value;
    this.setState({requiredSetting: r});
  }

  handleSave = (e) => {
    const $this = this;
    Utils.encodePassword($this.state.requiredSetting.emailPass,(encrypted) => {
      //console.log(encrypted);
      const obj = {
        setting: 'requiredSetting',
        settingVN: {
          emailPort: $this.state.requiredSetting.emailPort,
          emailServer: $this.state.requiredSetting.emailServer,
          emailSecure: $this.state.requiredSetting.emailSecure,
          emailService: $this.state.requiredSetting.emailService,
          emailUser: $this.state.requiredSetting.emailUser,
          emailPass: encrypted,
        },
        settingEN: ''
      };

      Utils.getData('setting/key','requiredSetting',(data) => {
        if (data.status === 'success') {
          if (data.results.length === 0) {
            Utils._add('setting', obj, (res) => {
              console.log(res);
            });
          } else {
            obj._id = data.results[0]._id;
            Utils._update('setting', obj, (res) => {
              console.log(res);
            });
          }
        }
      });
    });
  }

  render() {
    const $this = this;
    const classes = $this.props.classes;
    const source = {
      thead: [{title:'Key',value:'key'}, { title: 'Giá trị', value: 'settingVN' }, { title: 'Value', value: 'settingEN' }],
      tbody: function (n) {
        return n.setting !== 'requiredSetting' && ({
          _id: n._id,
          key: n.setting,
          settingVN: n.settingVN,
          settingEN: n.settingEN
        });
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục cấu hình/tùy chỉnh</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(e)=>this.setState({keyword:e.target.value.trim()})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={this.handleSearch}><SearchIcon /></Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={this.handleAdd}><AddIcon /></Fab>
          </Toolbar>
          <ReactTable apiList='settings' apiSingle='setting' apiTable='settings' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} apiSort={this.state.sorts}>
          </ReactTable>
        </Paper>
        <form className={classes.form} autoComplete="off" noValidate>
          <TextField id="emailServer" label="Mail server" className={classes.textField} value={this.state.requiredSetting.emailServer} onChange={this.requiredSettingChange} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField id="emailPort" label="Mail port" className={classes.textField} value={this.state.requiredSetting.emailPort} onChange={this.requiredSettingChange} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField id="emailSecure" label="Mail secure (0/1)" className={classes.textField} value={this.state.requiredSetting.emailSecure} onChange={this.requiredSettingChange} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField id="emailService" label="Mail service (gmail, hotmail ... or empty)" className={classes.textField} value={this.state.requiredSetting.emailService} onChange={this.requiredSettingChange} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField id="emailUser" autoComplete="off" label="Username" className={classes.textField} value={this.state.requiredSetting.emailUser} onChange={this.requiredSettingChange} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <TextField id="emailPass" autoComplete="off" type="password" label="Password" className={classes.textField} value={this.state.requiredSetting.emailPass} onChange={this.requiredSettingChange} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <FormGroup row className={classes.formRow} style={{justifyContent:'flex-end'}}>
            <Button variant="contained" color="primary" onClick={(e) => this.handleSave(e)}>{language.VN.btnSave}</Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

SettingList.propTypes = {
  classes: PropTypes.object.isRequired,
};
SettingList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(SettingList);
